import React from 'react'

export const useConfig = () => {
    const envVariables = () => {
        let envs = { auth: "" };
        if (process.env.NODE_ENV == 'development') {
            envs.auth = process.env.REACT_APP_DEV_AUTH;
        } else {
            envs.auth = process.env.REACT_APP_PROD_AUTH;
        }

        return envs;
    }

    return { envVariables }
}
