import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './App.css'; // Import the CSS file for styling
import { useConfig } from './config/useConfig';

const App = () => {
    const [form, setForm] = useState({
        client_id: '',
        client_secret: '',
        redirect_uri: '',
        username: ''
    });
    const [environment, setEnvironment] = useState('PRODUCTION'); // Default to SANDBOX
    const [message, setMessage] = useState(''); // Message state
    const [messageType, setMessageType] = useState(''); // Message type (success/error)
    // env variables hook
    const { envVariables } = useConfig();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleEnvironmentChange = (e) => {
        setEnvironment(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const authUrl = envVariables();
            const auth = await axios.post(`${authUrl.auth}/api/v1/ebay/form?environment=${environment}`, {
                [environment]: {
                    clientId: form.client_id,
                    clientSecret: form.client_secret,
                    redirectUri: form.redirect_uri,
                    baseUrl: (environment=='SANDBOX') ? 'api.sandbox.ebay.com' : 'api.ebay.com', // This is fixed for now, update if needed for production
                    username: form.username
                }
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (auth.status === 200) {
                const { url } = await auth.data;
                setMessage('Success! Redirecting...');
                setMessageType('success');
                console.log(url);
                window.location.href = url;
            }
        } catch (error) {
            setMessage('An error occurred. Please try again.');
            setMessageType('error');
            console.error(error);
        }
    };

    return (
        <div className="container">
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <div style={{width:'150px',height:'150px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="ebayLogoTitle" width="100%" height="100%" viewBox="0 0 122 48.592" id="gh-logo"><title id="ebayLogoTitle">eBay Home</title><g><path fill="#E53238" d="M24.355 22.759c-.269-5.738-4.412-7.838-8.826-7.813-4.756.026-8.544 2.459-9.183 7.915zM6.234 26.93c.364 5.553 4.208 8.814 9.476 8.785 3.648-.021 6.885-1.524 7.952-4.763l6.306-.035c-1.187 6.568-8.151 8.834-14.145 8.866C4.911 39.844.043 33.865-.002 25.759c-.05-8.927 4.917-14.822 15.765-14.884 8.628-.048 14.978 4.433 15.033 14.291l.01 1.625z"></path><path fill="#0064D2" d="M46.544 35.429c5.688-.032 9.543-4.148 9.508-10.32s-3.947-10.246-9.622-10.214-9.543 4.148-9.509 10.32 3.974 10.245 9.623 10.214zM30.652.029l6.116-.034.085 15.369c2.978-3.588 7.1-4.65 11.167-4.674 6.817-.037 14.412 4.518 14.468 14.454.045 8.29-5.941 14.407-14.422 14.454-4.463.026-8.624-1.545-11.218-4.681a33.237 33.237 0 01-.19 3.731l-5.994.034c.09-1.915.185-4.364.174-6.322z"></path><path fill="#F5AF02" d="M77.282 25.724c-5.548.216-8.985 1.229-8.965 4.883.013 2.365 1.94 4.919 6.7 4.891 6.415-.035 9.826-3.556 9.794-9.289v-.637c-2.252.02-5.039.054-7.529.152zm13.683 7.506c.01 1.778.071 3.538.232 5.1l-5.688.032a33.381 33.381 0 01-.225-3.825c-3.052 3.8-6.708 4.909-11.783 4.938-7.532.042-11.585-3.915-11.611-8.518-.037-6.665 5.434-9.049 14.954-9.318 2.6-.072 5.529-.1 7.945-.116v-.637c-.026-4.463-2.9-6.285-7.854-6.257-3.68.021-6.368 1.561-6.653 4.2l-6.434.035c.645-6.566 7.53-8.269 13.595-8.3 7.263-.04 13.406 2.508 13.448 10.192z"></path><path fill="#86B817" d="M91.939 19.852l-4.5-8.362 7.154-.04 10.589 20.922 10.328-21.02 6.486-.048-18.707 37.251-6.85.039 5.382-10.348-9.887-18.393"></path></g></svg>
                </div>
                {/* <h1>Form</h1> */}
            </div>
            <form onSubmit={handleSubmit} className="form">
                <div className="radio-group">
                    <label>
                        <input 
                            type="radio" 
                            name="environment" 
                            value="SANDBOX" 
                            checked={environment === 'SANDBOX'} 
                            onChange={handleEnvironmentChange} 
                        />
                        <span style={{fontWeight:'500'}}>SANDBOX</span>
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            name="environment" 
                            value="PRODUCTION" 
                            checked={environment === 'PRODUCTION'} 
                            onChange={handleEnvironmentChange} 
                        />
                        <span style={{fontWeight:'500'}}>PRODUCTION</span>
                    </label>
                </div>
                <input 
                    type="email" 
                    name="username" 
                    value={form.username} 
                    onChange={handleChange} 
                    placeholder='Email' 
                    required 
                />
                <input 
                    type="text" 
                    name="client_id" 
                    value={form.client_id} 
                    onChange={handleChange} 
                    placeholder='Client ID' 
                    required 
                />
                <input 
                    type="text" 
                    name="client_secret" 
                    value={form.client_secret} 
                    onChange={handleChange} 
                    placeholder='Client Secret' 
                    required 
                />
                <input 
                    type="text" 
                    name="redirect_uri" 
                    value={form.redirect_uri} 
                    onChange={handleChange} 
                    placeholder='Redirect URI (RuID)' 
                    required 
                />
                <a href={`${envVariables().auth}/api/v1/how-to-fill-form`} target="_blank" rel="noopener noreferrer" style={{ fontWeight: '300', display: 'block', marginTop: '10px', fontWeight: '500' }}>
                    Need help?
                </a><br/>
                <button type="submit">Submit</button>
                {message && (
                    <div className={`message ${messageType}`}>
                        {message}
                    </div>
                )}
            </form>
        </div>
    );
};

export default App;
